import React from "react";
import { motion } from "framer-motion"
import {
  Link,
  useLocation
} from "react-router-dom";
const Header = (props) => {
  let name = props.name
  const location = useLocation()

  const [locationState, setLocationState] = React.useState({name})

  React.useEffect(()=>{
    // console.log(location)
    if (location.state) {
      let _state = location.state
      setLocationState(_state)
    }
  },[location])

  
  return (
    <motion.header 
      whileHover={{ 
        scale: 1.1,
        transition: { duration: 1 },
      }}
      whileTap={{ scale: 0.9 }}
      className="header"
    >
      <Link to='/' className='logo'>
        <span>{locationState.name}</span>
      </Link>
    </motion.header>
  );
};

export default Header;
