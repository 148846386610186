import React from 'react'
import axios from "axios";
import {
  useLoaderData,
  Link
} from "react-router-dom";
import {VscClose} from 'react-icons/vsc'
import { InstagramEmbed } from 'react-social-media-embed';

export const postLoader = async ({ params }) => {
  const { id  } = params
  const res = await axios
    .get("https://joana-peres.com/api/blog/" + id)
    .then(res => {
      console.log(res.data)
      return res.data
    })
  .catch(error => console.error(error));

  return res
}


function Post() {

  const  post  = useLoaderData()

  return (
    <div 
      id='post' 
      className='post page'

     >
       <div className="post__container inner__container">
        <div href='#' key={post.id} className="post__item">
          <div className="post__item__text">
            <span className='head'>
     
              <p className='titulo'> {post.title}</p>
            </span>
            <div className='right'>
              <span dangerouslySetInnerHTML={{ __html: post.content }}></span>
          <div className="post__item__image">
          {post.galery.map((image) => {
                            return (

                         
                                <img key={image} src={'https://joana-peres.com' + image} alt={image}  style={{paddingBottom:'1em'}}/>
                   
                              );
                            })}
          </div>
         { post.video ? (          <iframe src={post.video} width={"100%"} height={"600"} title={'iframe'}></iframe>
            ): (<span></span>)
            
          }
          {post.instagramPost ? (<div style={{ display: 'flex', justifyContent: 'center' }}>
            <InstagramEmbed url={post.instagramPost} width={'100vw'} />
          </div>) : (<span></span>)}
          
            </div>
          </div> 
          <Link to={'..'}>
          
          <div className='moreButton'><VscClose/></div>
          </Link>
        </div>

{/* ))} */}
</div>  
    </div>
  )
}

export default Post