import React from 'react'
import axios from "axios";
import {
  useLoaderData,
  Link
} from "react-router-dom";
import {VscClose} from 'react-icons/vsc'


export const eventLoader = async ({ params }) => {
  const { id  } = params
  const res = await axios
    .get("https://joana-peres.com/api/agenda/" + id)
    .then(res => {
      console.log(res.data)
      return res.data
    })
  .catch(error => console.error(error));

  return res
}


function Event() {

  const  event  = useLoaderData()

  return (
    <div 
      id='event' 
      className='event page'

     >
       <div className="event__container inner__container">
        <div href='#' key={event.id} className="event__item">
          <div className="event__item__text">
            <span className='head'>
     
              <p className='titulo'> {event.title}</p>
            </span>
            <div className='right'>
              <span dangerouslySetInnerHTML={{ __html: event.content }}></span>
          <div className="event__item__image">
          {event.galery.map((image) => {
                            return (

                         
                                <img key={image} src={'https://joana-peres.com' + image} alt={image}  style={{paddingBottom:'1em'}}/>
                   
                              );
                            })}
          </div>
          <iframe src={event.video} width={"100%"} height={"600"} title={'iframe'}></iframe>

            </div>
          </div> 
          <Link to={'..'}>
          
          <div className='moreButton'><VscClose/></div>
          </Link>
        </div>

{/* ))} */}
</div>  
    </div>
  )
}

export default Event