import React, {useRef,createRef,useEffect} from 'react'
import { Outlet, NavLink } from "react-router-dom";
import {BsFacebook} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {FaTiktok} from 'react-icons/fa'
import {AiOutlineMail} from 'react-icons/ai'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
//Components
import Header from "../../components/parts/header";
import Footer from "../../components/parts/footer";
import Nav from "../../components/parts/Navigation";
import Nha3dAnimation from "../../components/Nha3dAnimation";

import {
  Link,
  useLoaderData
  
} from "react-router-dom";
// import i18n from "i18next";
// import {useTranslation} from 'react-i18next'
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
export default function RootLayout() {
  const ref = createRef();
  const inputRef = useRef(null);
  const main = useRef();



  // const {t,i18n } = useTranslation()
  // const changeLang = (lang) => {
  //   i18n.changeLanguage(lang)
  // }
  useEffect(() => {
      //   window.onload=()=>{
        
        let ctx = gsap.context((self) => {
    //       const images = self.selector('#scroll_app img');
    //       // gsap.to(ref.current.rotation, { x: "+=1", repeat: -1, ease: 'none', repeatRefresh: true  })
    //       gsap.set(scroll_dist.current, {
    //       width: '100%',
    //       height: gsap.getProperty(scroll_app.current, 'height'), // apply the height of the image stack
    //       onComplete:()=>{
    //         gsap.set([imgGroup.current,scroll_app.current], {opacity:1, position:'fixed', width:'100%', height:'100%', top:0, left:0, perspective:300}) 
    //         // gsap.set(, {opacity:1, position:'fixed', width:'100%', height:'100%', top:0, left:0, perspective:300}) 
    //         gsap.set(images, {
    //           position: 'absolute',
    //           attr:{ id:(i,t,a)=>{ //use GSAP's built-in loop to setup each image
    //             initImg(i,t);
    //             // console.log(a)
    //             return 'img'+i;
    //           }}
    //         })
      
    //         gsap.timeline({
    //           defaults:{ duration:1 },
    //           // onUpdate:()=>{ if (gsap.getProperty('#cursorClose','opacity')===1) closeDetail() }, //close detail view on scroll
    //           scrollTrigger:{
    //             // scroller: '#imgGroup',
    //           trigger: scroll_dist.current,
    //           start: 'top top',
    //           end: 'bottom bottom',
    //           scrub: 1
    //         }})
    //         // .fromTo('#txt1', {scale:0.6, transformOrigin:'50%'}, {scale:2, ease:'power1.in'}, 0)
    //         // .to('#txt1 path', {duration:0.3, drawSVG:0, stagger:0.05, ease:'power1.in'}, 0)

    //         .fromTo('.imgBox', {z:-3000}, {z:300, stagger:-0.3, ease:'none'}, 0.3)
    //         .fromTo('.imgBox img', {scale:3}, {scale:1.15, stagger:-0.3, ease:'none'}, 0.3)      
    //         .to('.imgBox', {duration:0, pointerEvents:'auto', stagger:-0.3}, 0.5)
    //         .from('.imgBox img', {duration:0.3, opacity:1, stagger:-0.3, ease:'power1.inOut'}, 0.3)
    //         .to('.imgBox img', {duration:0.3, opacity:0, stagger:-0.3, ease:'expo.inOut'}, 1.2)      
    //         .to('.imgBox', {duration:0, pointerEvents:'none', stagger:-0.3}, 1.27)
    //         .add('end')

    //         // .fromTo('#txt2', {scale:0.1, transformOrigin:'50%'},{scale:0.6, ease:'power3'}, 'end-=0.2')
    //         // .from('#txt2 path', {duration:0.4, drawSVG:0, ease:'sine.inOut', stagger:0.15}, 'end-=0.2')      
                  
    //         // intro animation
    //         gsap.from(window, {duration:1.4, scrollTo:gsap.getProperty(scroll_dist.current,'height')/3, ease:'power2.in'});
    //         gsap.from('.imgBox', {duration:0.2, opacity:0, stagger:0.06, ease:'power1.inOut'})
    //       }  
      
    //     })  
        
    //     function initImg(i,t){
    //       const box = document.createElement('div') // make a container div
    //       box.appendChild(t) // move the target image into the container
    //       document.getElementById('imgGroup').appendChild(box) // put the container into the imgGroup div
    //       gsap.set(box, {
    //           pointerEvents:'none',
    //           position:'absolute',
    //           attr:{ id:'box'+i, class:'imgBox' },
    //           width:t.width,
    //           height:t.height,
    //           // overflow:'hidden',
    //           top:'50%',
    //           left:'50%',
    //           x:t.dataset.x,
    //           y:t.dataset.y,
    //           xPercent:-50,
    //           yPercent:-50,
    //           perspective:500
    //         })
      
    //       t.onmouseover =()=> gsap.to('#cursorCircle', {duration:0.2, attr:{r:30, 'strokeWidth':4}})
          
    //       t.onmousedown =()=> {
    //         gsap.to(t, {z:-25, ease:'power2'})
    //         gsap.to('#cursorCircle', {attr:{r:40}, ease:'power3'})
    //       }
          
    //       t.onmouseup =()=> gsap.to(t, {z:0, ease:'power1.inOut'})
          
    //       t.onmouseout =()=> gsap.to('#cursorCircle', {duration:0.2, attr:{r:11, 'strokeWidth':3}})
          
    //       // t.onclick =()=> showDetail(t)
    //     }      
          if (ScrollTrigger.isTouch===1) { // on mobile, hide mouse follower + remove the x/y positioning from the images
          gsap.set('#cursor', {opacity:0}) 
          // gsap.set('.imgBox', {x:0, y:0})
        } else {
          
          // quickTo can be used to optimize x/y movement on the cursor...but it doesn't work on fancier props like 'xPercent'
        //  const cursorX = gsap.quickTo('#cursor', 'x', {duration:0.3, ease:'power2'})
        //  const cursorY = gsap.quickTo('#cursor', 'y', {duration:0.3, ease:'power2'})
          
          window.onmousemove =(e)=> {   
            // console.log(self.selector('#cursor'))   
            // gsap.to('.imgBox', { // move + rotate imgBoxes relative to mouse position
            //   xPercent:-e.clientX/window.innerWidth*100,
            //   yPercent:-25-e.clientY/window.innerHeight*50,
            //   rotateX:8-e.clientY/window.innerHeight*16,
            //   rotateY:-8+e.clientX/window.innerWidth*16
            // })
            
            // gsap.to('.imgBox img', { // move images inside each imgBox, creates additional parallax effect
            //   xPercent:-e.clientX/window.innerWidth*10,
            //   yPercent:-5-e.clientY/window.innerHeight*10
            // })
            
            // mouse follower
            // cursorX(e.clientX)
            // cursorY(e.clientY)
          }
        }
        }, main); // <- scopes all selector text inside the context to this component (optional, default is document)
    
        return () => ctx.revert();

        
    //     // function showDetail(t){
    //     //   gsap.timeline()
    //     //       .set('#detailTxt', {textContent:t.alt}, 0)
    //     //       .set('#detailImg', {background:'url('+t.src+') center no-repeat'}, 0)
    //     //       .fromTo('#detail', {top:'100%'}, {top:0, ease:'expo.inOut'}, 0)
    //     //       .fromTo('#detailImg', {y:'100%'}, {y:'0%', ease:'expo', duration:0.7}, 0.2)
    //     //       .fromTo('#detailTxt', {opacity:0}, {opacity:1, ease:'power2.inOut'}, 0.4)
    //     //       .to('#cursorCircle', {duration:0.2, opacity:0}, 0.2)
    //     //       .to('#cursorClose', {duration:0.2, opacity:1}, 0.4)
    //     // }
        
    //     // function closeDetail(){
    //     //   gsap.timeline()
    //     //       .to('#detailTxt', {duration:0.3, opacity:0}, 0)    
    //     //       .to('#detailImg', {duration:0.3, y:'-100%', ease:'power1.in'}, 0)
    //     //       .to('#detail', {duration:0.3, top:'-100%', ease:'expo.in'}, 0.1)
    //     //       .to('#cursorClose', {duration:0.1, opacity:0}, 0)
    //     //       .to('#cursorCircle', {duration:0.2, opacity:1}, 0.1)
    //     // }
    //     // document.getElementById('detail').onclick = closeDetail;
        

      // }
},);
  return (
    <div className='rootLayout' ref={inputRef}>
        <Header name="Joana Peres"/>
      {/* <Footer /> */}
      <Nav />
      <div id="falsum">
              <div id="poles"></div>
          </div>
      <div className="socialmedia">
   
              <div className='socialmedia__icons'>

              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/joanaperes'><BsFacebook/></a>
              <a target="_blank" rel="noreferrer" href='https://www.instagram.com/joanaperes_dance/'><BsInstagram/></a>
              {/* <Link to={'facebook.com'}><FaTiktok/></Link> */}
              <a
                href='!#'
                onClick={(e) => {
                window.location.href = "mailto:joperes@gmail.com ";
                }}
              >
                <AiOutlineMail/>
              </a>
              </div>

              {/* <div className="lang">
                <button onClick={() => changeLang('pt')} className='link'>{t('PT')}</button>
                <span className='link'>|</span>
                <button onClick={() => changeLang('en')} className='link'>{t('EN')}</button>
              </div> */}
      </div>
      <Nha3dAnimation ref={inputRef}/>
      <div width="1" height="1" fill="black" stroke="none" ref={main}>
    <span id="cursor" >
      {/* <rect id="cursorCircle" cx="0" cy="0" strokeWidth="3" width="1em" height="1em"/>
      <path id="cursorClose" d="M-25,-25 L25,25 M-25,25 L25,-25" opacity="0" strokeWidth="3.5"/> */}
    </span>
  </div>
      <Outlet />
    </div>
  )
}