import * as THREE from 'three'
import React, { forwardRef, useRef, useState, useEffect, Suspense, useLayoutEffect, useMemo } from "react";

import { Canvas, useThree, useFrame} from "@react-three/fiber";
// Deai - R3F
import {OrbitControls,Edges, MeshDistortMaterial,MeshReflectorMaterial, MeshRefractionMaterial, MeshWobbleMaterial } from "@react-three/drei";
// React Spring
// import { useSpring, a } from "@react-spring/three";
import { useTransition, useSpring, animated } from "@react-spring/three"
// import { a } from "@react-spring/three"
import { useLocation } from 'react-router-dom'
import { LayerMaterial, Depth, Fresnel } from 'lamina'
import { useControls, Leva } from 'leva'
import AnimatedRoutes from "./AnimatedRoutes";
const AnimatedMeshDistortMaterial = animated(MeshDistortMaterial)
function Shapes({ transition }) {
  
  // const { nodes } = useGLTF("/bomb-gp.glb")
  // const textures = useTexture(["/ao.jpg", "/normal.jpg", "/height.png", "/roughness.jpg"])
  // useLayoutEffect(() => {
  //   textures.forEach((texture) => ((texture.wrapT = texture.wrapS = THREE.RepeatWrapping), texture.repeat.set(4, 4)))
  // }, [textures])

  return transition(({...props }, location) => (
    <animated.group {...props} >

        <Cube /> 
        {/* {location.pathname} */}
    </animated.group>
  ))
}
const colors = ['red','green','blue','orange','purple']
// function randomColor(colors) {
//   return colors[Math.floor(Math.random() * colors.length)];
// }
function Cube() {
  const { width, height } = useThree((state) => state.viewport)
  const mesh = useRef()
  const ref = useRef()
  const config = useControls({
    backside: false,
    samples: { value: 16, min: 1, max: 32, step: 1 },
    resolution: { value: 256, min: 64, max: 2048, step: 64 },
    transmission: { value: 0.95, min: 0, max: 1 },
    roughness: { value: 0.5, min: 0, max: 1, step: 0.01 },
    clearcoat: { value: 0.1, min: 0, max: 1, step: 0.01 },
    clearcoatRoughness: { value: 0.1, min: 0, max: 1, step: 0.01 },
    thickness: { value: 200, min: 0, max: 200, step: 0.01 },
    backsideThickness: { value: 200, min: 0, max: 200, step: 0.01 },
    ior: { value: 1.5, min: 1, max: 5, step: 0.01 },
    chromaticAberration: { value: 1, min: 0, max: 1 },
    anisotropy: { value: 1, min: 0, max: 10, step: 0.01 },
    distortion: { value: 0, min: 0, max: 1, step: 0.01 },
    distortionScale: { value: 0.2, min: 0.01, max: 1, step: 0.01 },
    temporalDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
    attenuationDistance: { value: 0.5, min: 0, max: 10, step: 0.01 },
    attenuationColor: '#ffffff',
    color: '#ffffff',
  })
  // const { mouse, clock } = useThree()
  // const [rEuler, rQuaternion] = useMemo(() => [new THREE.Euler(), new THREE.Quaternion()], [])

  const { gradient } = useControls({ gradient: { value: 100, min: width, max: width*10 },  hidden:true })
  // const initialAnimationState = {}
  const [rotation, setRotation] = useState({x: 0, y: 0, z: 0})
  const { x, y, z } = useSpring({ x: rotation.x, y: rotation.y, z: rotation.z })
  const location = useLocation()
  const [prevId, setPrevId] = useState(null);
  const [color, setColor] = useState(colors[0]);
  // const material = useRef()
// const [fresnel, setFresnel] = useState(0.1)
const [num, setNum] = useState(0);
// const materialProps = useSpring({ color, fresnel })
function randomNumberInRange(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
  useEffect(() => {
    const currentId = location && location.state && location.state.id;
    const currentColor = colors[currentId % colors.length];
    let direction = null;
      // const newColor = randomColor(colors);
      // setColor(newColor);
      const randomIndex = Math.floor(Math.random() * colors.length);
    setColor(colors[randomIndex]);
      if (prevId) {
          if (currentId > prevId) {
              direction = 'positive';
          } else if (currentId < prevId) {
              direction = 'negative';
          } else {
            console.log('same')
            direction = 'same';
          }
      }
      setPrevId(currentId);
      setNum(randomNumberInRange(0, 100));
      setRotation(prevState => {
        if (direction === 'positive') {
            return { ...prevState, y: prevState.y += Math.PI, x: prevState.x = 0 }
        } else if (direction === 'negative') {
            return { ...prevState, y: prevState.y -= Math.PI, x: prevState.x = 0}
        
      } else if (direction === 'same') {
        return { ...prevState, x: prevState.x -= Math.PI }
      } else {
            return prevState;
        }
    });
  }, [location,prevId])
  useFrame((state) => {

    state.camera.position.x = THREE.MathUtils.lerp(state.camera.position.x, 1 + state.mouse.x / .915, 0.075)
    state.camera.position.y = THREE.MathUtils.lerp(state.camera.position.y, -1 + state.mouse.y / .915, 0.075)
    // console.log(state.scene.children[4].children[0].rotation.y)
    // rEuler.set((-mouse.y * Math.PI) / 20, (mouse.x * Math.PI) / 20, 0)
    // mesh.current.quaternion.slerp(rQuaternion.setFromEuler(rEuler), 0.1)
   

  })
  // useRender(({ gl, scene, camera }) => {
  //   scene.rotation.y = rotation;
  // })
  return (
    <animated.group rotation-y={y} rotation-x={x} >
    <mesh ref={mesh} >
      <boxGeometry attach="geometry" args={[width, height, width* num]} />
      {/* <meshStandardMaterial side={THREE.BackSide} attachArray="material-0" color="#f09b12" /> */}
      {/* <meshPhysicalMaterial side={THREE.BackSide} depthWrite={false} transmission={1} thickness={10} roughness={0.65} /> */}
      {/* <shaderMaterial
      // side={THREE.BackSide}
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
      /> */}
      <meshStandardMaterial color={color} side={THREE.BackSide}/>
        <LayerMaterial ref={ref} toneMapped={false} side={THREE.BackSide}>
        <Depth colorA="#999999" colorB="black" alpha={1} mode="normal" near={1 * gradient} far={width} origin={[0, 0, 0]} />
        <Depth colorA="white" colorB="black" alpha={1} mode="overlay" near={5 * gradient} far={width} origin={[0, 0, 0]} />
        <Depth colorA="grey" colorB={color}  alpha={1} mode="add" near={10 * gradient} far={width} origin={[0, 0, 0]} />
        <Fresnel mode="add" color="white" intensity={0.01} power={2} bias={0.1} />
      </LayerMaterial>
      {/* <MeshReflectorMaterial {...config} color={color} toneMapped={false} side={THREE.BackSide}/> */}
            
      {/* <Edges color={color} /> */}
      {/* <gridHelper /> */}
    </mesh>
    </animated.group>
  )
}
function newAnimationState(location) {
  // if (location.pathname === '/about') {
    //   return {...aboutPageAnimationState}
    // } else if (location.pathname === '/3d-animation') {
      //   return {}
      // } else {
        //   return {...homePageAnimationState}
        // }
          return alert(location)
}
const MyScene = () => {
  const [clicked, setClicked] = useState(false)

  const springs = useSpring({
    color: clicked ? '#569AFF' : '#ff6d6d',
  })

  const handleClick = () => setClicked(s => !s)

  return (
    <mesh onClick={handleClick}>
      <sphereGeometry args={[1.5, 64, 32]} />
      <AnimatedMeshDistortMaterial
        speed={5}
        distort={0.5}
        color={springs.color}
      />
    </mesh>
  )
}
const Nha3dAnimation = forwardRef((props, ref) => {
  // passing the ref to a DOM element, 
  // so that the parent has a reference to the DOM node
//   return <input style={{color: "red"}} {...props} ref={ref} />
// });
// function Nha3dAnimation() {
  // const location = useLocation()
  // const initialAnimationState = {}
  // const [animationState, setAnimationState] = useState(initialAnimationState)

  // useEffect(() => {
  //   setAnimationState(newAnimationState)
  // }, [location])
  const containerRef = useRef(null)
  const location = useLocation()
  // const transition = useTransition(location, {
    
  //   // rotation:[Math.PI/15,0,0],
    
  //   from: { rotation: [Math.PI/15, Math.PI, 0],position: [0, 0, 0]},
  //   enter: { rotation: [Math.PI/15, 0, 0] ,position: [0, 0, 0] },
  //   leave: { rotation: [Math.PI/15, -Math.PI, 0] ,position: [0, 0, 0]},
  //   config: {duration: 500,precision: 0.0001,}
  // })
  // const props = useSpring({
  //   to: async next => {
  //     await next({ position: [3, 0, 0] });
  //     await next({ position: [0, 0, 0] });
  //   },
  //   from: { position: [0, 0, 0] },
  //   config: { duration: 1000 }
  // });
  return (
    // <Canvas>
    //   <mesh onUpdate={() => animationState.update()} />
    //   <div>{/* Your HTML content goes here */}</div>
    // </Canvas>
    
    <div ref={containerRef} style={{ display: 'block' }}>
        <Canvas
        className='canvas__container'
        style={{position:'fixed'}}
        eventSource={ref}
        {...props} 
        shadows
        camera={{ position: [0, 0, -10], fov: 60 }}>
            {/* This light makes things look pretty */}
            <ambientLight intensity={1} />
            {/* Our main source of light, also casting our shadow */}
            <directionalLight
              castShadow
              position={[0, 10, 0]}
              intensity={5}
          />
            {/* A light to help illumnate the spinning boxes */}
            <pointLight position={[0, 0, 100]} intensity={1} />
            <pointLight position={[0, 0, 100]} intensity={1} />
            {/* <PresentationControls global zoom={0.8} rotation={[0, -Math.PI / 4, 0]} polar={[0, Math.PI / 4]} azimuth={[-Math.PI / 4, Math.PI / 4]}> */}
            
              <group>
                    {/* This mesh is the plane (The floor) */}
                    <Suspense fallback={null}>
                    {/* <Caption>{`Joana Peres\nBailarina\nCoreógrafa\nArtista plástica\nPesquisadora\nDanças africanas.`}</Caption> */}
                    {/* <Imagess url={Img} >{Img}</Imagess>  */}
                        <Cube  />
                    </Suspense>
                    {/* <Caption>    
                      <h1>Joana Peres</h1>
                      <h5>Bailarina</h5>
                      <h5>Coreógrafa</h5>
                      <h5>Artista plástica</h5>
                      <h5>Pesquisadora</h5>
                      <h5>Danças africanas</h5>
                  </Caption> */}
                  {/* <Cube scale={[0.5, 1, 0.5]} /> */}
                  
              </group>
            <OrbitControls enableRotate={false} enableZoom={false} enablePan={false}/>
            {/* <Rig /> */}
            <Leva hidden />
        </Canvas>
        {/* <AnimatedRoutes/> */}
    </div>
  )
})

export default Nha3dAnimation
