import React, { useState, useEffect, useRef } from 'react'
import { getAllPortfolioItems, getPortfolioItemsByCategory } from '../../apiClient';
import * as THREE from 'three'
import { motion } from "framer-motion"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  NavLink,
  Routes,
  Route,
  useLocation,
  Outlet,
  useLoaderData
  
} from "react-router-dom";
import placeholder from '../../../static/images/image.png'


export const worksLoader = async () => {
  
  const res = await     getAllPortfolioItems()
  .then(response => {
    console.log(response.data);

    return response.data
  })
  .catch(error => console.error(error));

  return res
}
export const worksCategoryLoader = async () => {
  
  const res = await     getPortfolioItemsByCategory()
  .then(response => {
    console.log(response.data);

    return response.data
  })
  .catch(error => console.error(error));

  return res
}






const MyContext = React.createContext();
const useData = () => {
  const context = React.useContext(MyContext);

  if (!context) {
    throw new Error("useData must be used within a <Parent />");
  }

  return context;
};
function Item({ index, position, scale, c = new THREE.Color(), ...props }) {
  
  const [hovered, hover] = useState(false)
  const [data, setData] = useData();
  const over = (object) =>{
    setData(object.target.getAttribute('data-title'))
    hover(true)
  }
  const out = () => hover(false)


  return ( 
<NavLink to={props.item.category+'/'+props.item.id}>
            <div className='work__images__wrapper__item' onMouseEnter={over} onMouseLeave={out}>
              <div className='work__mobile_info' >
                 <h3>{props.item.title}</h3>
                  <p>{props.item.category}</p>
              </div>
            <LazyLoadImage
              src={props.url} // use normal <img> attributes as props
              data-title={props.item.slug}
              placeholderSrc={placeholder}
              effect='blur'
              />
              <div className='work__mobile_info'>
                 <h3>{props.item.description}</h3>
                  <p>{props.item.published}</p>
              </div>
            </div>
</NavLink>
        )
}

function Items({ w = 0.5, gap = 0.15, ...props }) {
  const xW = w + gap
  return (
    <div className='work__images__wrapper'>
       {props.items.map((item,i)=> 
 
          <Item key={i} index={i} hoverIndex={props.hoverIndex} setHoverIndex={props.setHoverIndex} position={[i * xW, 0, 0]} scale={[w, 4, 1]} url={'https://joana-peres.com' +item.galery[0]} item={item}/>
     
        
        )}

    </div>
  )
}

function Works() {
  const portfolioItems = useLoaderData()
  const [hoverIndex, setHoverIndex] = React.useState(null);
  const value = [hoverIndex, setHoverIndex];
  const [selectedCategory, setSelectedCategory] = useState('');

  const filteredData = selectedCategory
    ? portfolioItems.filter(item => item.category === selectedCategory)
    : portfolioItems;
  const handleCategoryClick = (category, e) => {
      e.preventDefault();
      setSelectedCategory(category);
    };

  const navCat =  {
    'residencias': 'Residencias',
    'dance': 'performance',
    'mae': 'movement of african expression',
  }
  return (
    <div 
        id='work' 
        className='work page'

        >

                <div className="catmenu">
            <div className='catmenu_list'>
            <motion.div className='catmenu_list_item'  
              whileHover={{scale: 1.1,transition: { duration: 1 },}} 
              whileTap={{ scale: 1 }}
              >
              <NavLink to={''} className={''===selectedCategory ? 'active' : ''} data-title={''} onClick={(e) => handleCategoryClick(e.target.dataset.title, e)}>&#8780;</NavLink>
              </motion.div>
              
              
              {Object.entries(navCat).map(([item, value]) =>{

                return(     
                <motion.div className='catmenu_list_item'  
              whileHover={{scale: 1.1,transition: { duration: 1 },}} 
              whileTap={{ scale: 1 }}
              key={item}>
              <NavLink to={item} className={item===selectedCategory ? 'active' : ''} data-title={item} onClick={(e) => handleCategoryClick(e.target.dataset.title, e)}>{value.toString()}</NavLink>
              </motion.div>)
         
              })}

              
            </div>
          </div>
            <div className="work__container inner__container">
            <MyContext.Provider value={value}>
                      {/* <>
                      {   hoverIndex 
                          ?
                          portfolioItems.filter(item=> item.slug === hoverIndex).map(r =>{
                            return (
                              <div className='work__dektop_info' key={r.id} style={{'textAlign':'center','paddingBottom':'1em'}}>
                                 <h3>{r.title}</h3>
                                 {console.log(value,'reiiiiiiiiiiiiiiiiiii')}
                                  <h4>{r.category}</h4>
                              </div>
                        

                            )
                          }) 
                          : 
                          <div className='work__dektop_info' style={{'textAlign':'center','paddingBottom':'1em'}}>
                              <h1>{portfolioItems[0].title}</h1> 
                              <h4>{portfolioItems[0].category}</h4>
                          </div>    
                      }
                      </> */}
                      <Items items={filteredData} />
                      <>
                      {   hoverIndex 
                          ?
                          filteredData.filter(item=> item.slug === hoverIndex).map(r =>{
                            return (
                              <div className='work__dektop_info' key={r.id} style={{'textAlign':'center','paddingBottom':'2em'}}>
                        
                                <div className="group">
                                  <h2 className='item l'>{r.title}</h2>
                                  <span>|</span>
                                  <h3 className='item r'>{r.description}</h3> 
                                </div>
                                <div className="group">
                                  <h3 className='item l'>{r.category}</h3>
                                  <span>|</span>
                                  <h3 className='item r'>{r.year}</h3>
                                </div>    
                              
                              </div>
                        

                            )
                          }) 
                          : 
                          <div className='work__dektop_info' style={{'textAlign':'center','paddingBottom':'2em'}}>
                              <div className="group">
                              <h2 className='item l'>{filteredData[0].title}</h2>
                              <span>|</span>
                              <h3 className='item r'>{filteredData[0].description}</h3> 
                              </div>
                              <div className="group">
                              <h3 className='item l'>{filteredData[0].category}</h3>
                              <span>|</span>
                              <h3 className='item r'>{filteredData[0].year}</h3>
                              </div>    
                          </div>    
                      }
                      </>
                </MyContext.Provider>


          </div>  

    </div>
  )
}

export default Works
