import React from 'react'
import { Link } from "react-router-dom";
import { useState, useEffect,useRef } from "react";
import {
  useLocation
} from "react-router-dom";
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
}

function Nav() {
   const location = useLocation()
  const [navWidth, setNavWidth] = React.useState(true);
  const isMobile = useMediaQuery('(min-width: 425px)');
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const timerId = useRef(null)
  const handleMouseEnter = () => {
    
 if (!isMobile  ) { 
  // alert('')  
  } else{
    setNavWidth(true);
  }
 };

 const handleMouseLeave = () => {
    setNavWidth(false);
 };
 const handleMouseClick = () => {
  if (!isMobile  ) {

    setNavWidth(true);
      window.clearTimeout(timerId.current);
      //var millisecBeforeRedirect = 10000; 
      // if (!isMobile  ) {
        timerId.current = window.setTimeout(() =>{
        handleMouseLeave()
          
        },4000); 
        // window.clearTimeout(timer);
    } else {}
 };
  const navStyles = {
    width: navWidth ? '50vw' : '4em',
    marginLeft: navWidth ? 'calc(50% - 25vw)' : 'calc(50% - 2em)',
    height: isDesktop ? '4em' : !isMobile && navWidth ? '30em' : '4em'
  };

 useEffect(() => {
    window.clearTimeout(timerId.current);
    //var millisecBeforeRedirect = 10000; 
    // if (!isMobile  ) {
      timerId.current = window.setTimeout(() =>{
      setNavWidth(false);
        
      },1000); 
    // } 
   }, [location,isMobile])
  return (
  
              <nav 
              className={"navbar " + (navWidth ? 'active' : '')}
              style={navStyles}
              onMouseEnter={handleMouseEnter}
              onClick={handleMouseClick}
              onMouseLeave={handleMouseLeave}
              >
                
                
                <div className="nav__container" style={{display:navWidth ? 'flex' : 'none', flexDirection: !isMobile && navWidth ? 'column' : 'row'}}>
                    <Link className="nav__item" to="/work" state={{name:'Work', id:1}}>Work</Link>
                    <Link className="nav__item" to="/blog" state={{name:'Blog', id:2}}>Blog</Link>
                    <Link className="nav__item" to="/" state={{name:'Joana Peres', id:3}}>Home</Link>
                    <Link className="nav__item" to="/agenda" state={{name:'Agenda', id:4}}>Agenda</Link>
                    <Link className="nav__item" to="/about" state={{name:'About', id:5}}>About</Link>
                    {/* <Link className="nav__item" to="/drawing" state={{name:'Drawing'}}>Drawing</Link> */}
                
                
                </div>
                <div className="project_nav">
                    
                    <div id="uptack" rel="history">
                        <div className="poles" style={{display:navWidth ? 'none' : 'flex'}}></div>
                        <div className="fissure" style={{display:navWidth ? 'none' : 'flex'}}></div>
                    </div>
                    {/* <a id="index" href="/" rel="show_index">
                    <div className="poles"></div>
                    <div className="fissure"></div>
                    </a> */}
                </div>
                   
        </nav>
    
  )
}

export default Nav
