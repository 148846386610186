import React from 'react'
import { Outlet, NavLink } from "react-router-dom";

export default function BlogLayout() {
  const containerRef = React.useRef(null)

  return (
    <div className='blogLayout' ref={containerRef}>
      <Outlet />
    </div>
  )
}

