import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholder from '../../../static/images/image.png'
import { getAllBlogItems } from '../../apiClient';

import {
  Link,
  useLoaderData
  
} from "react-router-dom";

export const blogLoader = async () => {
  
  const res = await     getAllBlogItems()
  .then(response => {
    // console.log(response.data);
    return response.data
  })
  .catch(error => console.error(error));

  return res
}

function Blog() {
  const  urls  = useLoaderData()
  return (
    <div 
      id='blog' 
      className='blog page'
     >
       <div className="blog__container inner__container">
            {urls.map(item => (
              <div href='#' key={item.id} className="blog__item">
          <Link to={'/blog/'+item.id} data-title={item.id}>
          <div className="blog__item__text">
            <div className="blog__item__text__content">
            <span className='left'>
     
              <p className='titulo'> {item.title}</p>
            </span>
            <span className='right' dangerouslySetInnerHTML={{ __html: item.content }}></span>
              <h5 style={{textDecoration:'underline'}}>read more</h5>
           </div>
          <div className="blog__item__image">
          {/* <img  src={'https://joana-peres.com/'+item.galery[0]} alt="Logo" /> */}
                <LazyLoadImage
              height={'100%'}
              // placeholderSrc={Img}
              src={'https://joana-peres.com/'+item.galery[0]} // use normal <img> attributes as props
              placeholderSrc={placeholder}
              effect='blur'
              />
          </div>
          </div> 
          
              
          </Link>
        </div>

))}
</div>  
    </div>
  )
}

export default Blog