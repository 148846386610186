import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://joana-peres.com/api/',
});

export const getAllHomeItems = () => instance.get('1');
export const getAllAboutItems = () => instance.get('2');
export const getAllAgendaItems = () => instance.get('agenda/');
export const getAllPortfolioItems = () => instance.get('work/');
export const getPortfolioItemsByCategory = (categorySlug) => instance.get(`work/?category=${categorySlug}`);
export const getPortfolioItemsDetail = ({itemCategory, itemId}) => instance.get(`work/?category=${itemCategory}/?id=${itemId}`);
export const getAllBlogItems = () => instance.get('blog/');
export const getBlogItemsDetail = (postID) => instance.get(`work/?id=${postID}`);
