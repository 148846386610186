import React from 'react'

import {
  createBrowserRouter, 
  createRoutesFromElements,
  RouterProvider,
  Route,
  useLocation
} from "react-router-dom";
import Home, {homeLoader} from "./pages/HomePage"
import About, {aboutLoader} from "./pages/About"
import Works, {worksLoader} from "./pages/works/Works"
import Work, {workLoader} from "./pages/works/Work"
import Blog, {blogLoader} from "./pages/blog/Blog"
import Post, {postLoader} from "./pages/blog/Post"
import Event, {eventLoader} from "./pages/agenda/Event"
import NotFound from "./pages/NotFound"
import Agenda, {agendaLoader}  from "./pages/agenda/Agenda"

// layouts
import RootLayout from './layouts/RootLayout'
import WorkLayout from './layouts/WorkLayout'
import BlogLayout from './layouts/BlogLayout'

import SlideRoutes from 'react-slide-routes';
import { CSSTransition } from "react-transition-group";


const router = createBrowserRouter(
  createRoutesFromElements(
    // <Route key={location}>
    <Route path="/" element={<RootLayout />}>
          <Route index loader={homeLoader} errorElement={<NotFound />}  element={<Home />} />
          <Route path='work' element={<WorkLayout />} >
            <Route index loader={worksLoader} errorElement={<NotFound />} element={<Works />}/>
            <Route path=':category' loader={worksLoader} element={<Work />}/>
            <Route path=':category/:id' loader={workLoader} errorElement={<NotFound />}  element={<Work />}/>
          </Route>
          <Route path='blog' element={<BlogLayout />} >
            <Route index loader={blogLoader} errorElement={<NotFound />}  element={<Blog />}/>
            {/* <Route path=':category' loader={worksCategoryLoader} element={<Work />}/> */}
            <Route path=':id' loader={postLoader} errorElement={<NotFound />}  element={<Post />}/>
          </Route>
          <Route path='agenda' element={<BlogLayout />} >
            <Route index loader={agendaLoader} errorElement={<NotFound />}  element={<Agenda />}/>
            {/* <Route path=':category' loader={worksCategoryLoader} element={<Work />}/> */}
            <Route path=':id' loader={eventLoader} errorElement={<NotFound />}  element={<Event />}/>
          </Route>
          {/* <Route path='agenda' loader={agendaLoader} errorElement={<NotFound />}  element={<Agenda />} /> */}
          <Route path='about' loader={aboutLoader} errorElement={<NotFound />} element={<About />} />
          <Route path="*" element={<NotFound />} />
    {/* </Route> */}
  </Route>

    )
    )
function AnimatedRoutes() {

      return (

        <RouterProvider router={router} />

      )
  
}

export default AnimatedRoutes

