import React from 'react'
import { getAllAgendaItems} from '../../apiClient';
import {
  Link,
  useLoaderData
  
} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholder from '../../../static/images/image.png'
export const agendaLoader = async () => {
  
  const res = await     getAllAgendaItems()
  .then(response => {
    console.log(response.data);

    return response.data
  })
  .catch(error => console.error(error));

  return res
}
function Agenda() {
  const agendaItems = useLoaderData()

  return (
    <div 
      id='agenda' 
      className='agenda'
      // initial={{opacity: 0,overflow: 'hidden'}}
      // animate={{opacity: 1, overflow: 'auto'}}
      // exit={{opacity: 0,overflow: 'hidden'}}  
     >
      <div className='inner__container agenda__container' >
      {agendaItems.map((item,i)=> 
 

 <Link to={'/agenda/'+item.id} data-title={item.id} key={i}>

        <div className="agenda__content">
          <div className="agenda__content__text">
            <div className="title">
              <h1>{item.title}</h1>
            </div>
            <div className="discription">
              {/* <p>{item.date}</p> */}
              <h2>{item.category}</h2>
            </div>
          </div>
          <div className="agenda__content__image">
              <LazyLoadImage
              effect="blur"
              // height={'auto'}
              src={'https://joana-peres.com' +item.galery[0]} // use normal <img> attributes as props
              placeholderSrc={placeholder}
              // width={'100%'} 
              data-title={item.id}
              />

          </div>
          <div className="agenda__content__date">
            <div className="title">
              <h1>{item.date}</h1>
            </div>
            <div className="discription">
              <h2>{item.time}</h2>
              {/* <p>{item.category}</p> */}
            </div>
          </div>

        </div>
</Link>
          )}
      </div>
      </div>
  )
}

export default Agenda
