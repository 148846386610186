import React from 'react'
import { getAllAboutItems } from '../apiClient';
import {
  Link,
  useLoaderData
  
} from "react-router-dom";
import Ztext from 'react-ztext'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholder from '../../static/images/image.png'
export const aboutLoader = async () => {
  
  const res = await     getAllAboutItems()
  .then(response => {
    console.log(response.data);
    return response.data
  })
  .catch(error => console.error(error));

  return res
}
function About() {
  const  data  = useLoaderData()
  return (
    <div 
        id='about' 
        className='about page'

     >
      <div className="about__container inner__container">
        <div className="about__container__text">
          <span className='left'>

          <p className='titulo'> {data.description}</p>
          </span>
          <span className='right'>
          <span dangerouslySetInnerHTML={{ __html: data.content }}></span>
         </span>
        </div> 
        <div className="about__container__image">
                          <Ztext
                          depth='0'
                          direction='both'
                          event='pointer'
                          eventRotation='45deg'
                          eventDirection='default'
                          fade={false}
                          layers={1}
                          perspective='400px'
                          style={{
                            fontSize: '6rem',
                            transform: 'rotateY(45%) translate3d(-5%, -50%, -100px)'
                          }}
                        >
                        <span>
                                <LazyLoadImage
                                    height={'100%'}
                                    // placeholderSrc={Img}
                                    src={data.galery[0]} // use normal <img> attributes as props
                                    placeholderSrc={placeholder}
                                    effect='blur'
                                    />
                              </span>
                        </Ztext>
        <span>-</span>
        </div>
     </div>  
    </div>
  )
}

export default About
