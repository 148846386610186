import React from 'react'
import {
  Link
} from "react-router-dom";
function NotFound() {
  return (
    <div 
        id='about' 
        className='about page'

     >
      <div className="notfound">
        <div className="">
              <h3>404 Not found </h3>

        </div> 
        <div className="">
        <Link to={'..'}>

              <div className='moreButton'><h3>go back</h3></div>

        </Link>
        <span>.</span>
        </div>
     </div>  
    </div>
  )
}

export default NotFound
