import React from 'react'
import {VscClose} from 'react-icons/vsc'
import axios from 'axios';
import {
  useLoaderData,
  Link
} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholder from '../../../static/images/image.png'

function Work() {

  const work = useLoaderData()
  console.log('object',work);

  return (
    <div 
        id='worksDetail' 
        className='workDetail page'
        >
    
            <div className="workDetail__container inner__container">
      {      <div key={work.id} href='#' className="workDetail__item">
                <div className="workDetail__item__text">

                  <h1 className='titulo'>{work.title}</h1>
                  <div className='content'>

                    <div className='content_item'>
                      <span dangerouslySetInnerHTML={{ __html: work.excerpt }}></span>

                      </div>
                    <p className='content_item'>{work.description}</p>
                    <div className="item__image">
                    {work.galery.map((image) => {
                    return (
                      
                      
                                <LazyLoadImage
                                key={image}
                                height={'100%'}
                                src={'https://joana-peres.com' + image} // use normal <img> attributes as props
                                placeholderSrc={placeholder}
                                effect='blur'
                                />
                      );
                    })}
                    </div>
                    <iframe src={work.video} width={"100%"} height={"600"} title={'iframe'}></iframe>

                  </div>
                </div> 
                <Link to={'..'}>

                <div className='moreButton'><VscClose/></div>

                  </Link>
              </div>}


          </div>  
    </div>
  )
}

export default Work
export const workLoader = async ({ params }) => {
  const { category, id  } = params
  console.log(category, id)
  const res = await axios
    .get("https://joana-peres.com/api/work/" + category +'/'+ id)
    .then(res => {
      console.log(res.data)
      return res.data
    })
    
    .catch(error => console.log(error)); 

  return res
}