import React from "react";
import "./App.scss";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  return (
    <>
      <>
      <AnimatedRoutes/>
      </>
    </>
  );
}
export default App