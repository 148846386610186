import React, {  useEffect, Suspense, useState, useLayoutEffect, useRef } from "react";
import { getAllHomeItems, getAllAgendaItems } from '../apiClient';
import {
  Link,
  useLoaderData
  
} from "react-router-dom";
import Ztext from 'react-ztext'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholder from '../../static/images/image.png'

function Caption({ children }) {
  return (
    <div className='homemenu'>              
    <Ztext
    depth='1rem'
    direction='both'
    event='pointer'
    eventRotation='45deg'
    eventDirection='default'
    fade={false}
    layers={10}
    perspective='300px'
    style={{
      fontSize: '6rem',
    }}
  >
    <span className='menu' role='img' aria-label='emoji'>
    <Link className="nav__item" to="/work" state={{name:'Work', id:1}}>Work</Link>
                    <Link className="nav__item" to="/blog" state={{name:'Blog', id:2}}>Blog</Link>
                    <Link className="nav__item" to="/" state={{name:'Joana Peres', id:3}}>Home</Link>
                    <Link className="nav__item" to="/agenda" state={{name:'Agenda', id:4}}>Agenda</Link>
                    <Link className="nav__item" to="/about" state={{name:'About', id:5}}>About</Link>
    </span>
  </Ztext>
                    </div>
  )
}



const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
}


export const homeLoader = async () => {
  
  const res = await     getAllHomeItems()
  .then(response => {
    console.log(response.data);
    return response.data
  })
  .catch(error => console.error(error));

  return res
}
export const agendaLoader = () => {
  
  const res = getAllAgendaItems()
  .then(response => {
    console.log(response.data);
    return response.data
  })
  .catch(error => console.error(error));

  return res
}

function Home() {
const main = useRef();
function randomNumberInRange(min, max) {
  //  get number between min and max
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
// const agendaItems = agendaLoader()
  const  data  = useLoaderData()
  const  url = 'https://joana-peres.com'+ data.galery[0]
  const [agendaItems, setAgendaItems] = useState([]);

  useEffect(() => {
    getAllAgendaItems()
      .then(response => {
        console.log(response.data);
        setAgendaItems(response.data)
      })
      .catch(error => console.error(error));
  }, []);
  
  return (
    <div 
      id="home" 
      className="home page"
      ref={main}
      >
    

    <div className="agenda__stripe">
                <Link to={'agenda'} className="agenda__stripe__title" state={{name:'Agenda', id:4}}> <b> Next Events</b></Link>
                <div id="eventsList" className="agenda__stripe__event__lis scroll ">
                <ul id="eventsList" className="agenda__stripe__event__list__conten m-scroll">
                  {agendaItems.map((item,i)=> 
 
 

                      <li>
                        <Link to={'/agenda/'+item.id} data-title={item.id} key={i}>
                        <span><b> {item.title}</b></span> <span>|</span><span>{item.category}</span><span>|</span><b><span> {item.date}</span> </b>
                      </Link>
                        </li>

                  )}
                {agendaItems.map((item,i)=> 
 
 

                  <li>
                    <Link to={'/agenda/'+item.id} data-title={item.id} key={i}>
                    <span>{item.title}</span> <span>|</span><span>{item.category}</span><span>|</span><b><span> {item.date}</span> </b>
                  </Link>
                    </li>

                  )}

                </ul>
                </div>
              </div>

      <div className="home__container">

                    <Caption >{}</Caption>
        
                    <div className='homeimage'>              
                          <Ztext
                          depth='0'
                          direction='both'
                          event='pointer'
                          eventRotation='45deg'
                          eventDirection='default'
                          fade={false}
                          layers={1}
                          perspective='400px'
                          style={{
                            fontSize: '6rem',
                            transform: 'rotateY(45%) translate3d(-5%, -50%, -100px)'
                          }}
                        >
                        <span>
                                <LazyLoadImage
                                    height={'100%'}
                                    // placeholderSrc={Img}
                                    src={url} // use normal <img> attributes as props
                                    placeholderSrc={placeholder}
                                    effect='blur'
                                    />
                              </span>
                        </Ztext>
                    </div>
        
        
        </div> 
   
       
    </div>
  )
}

export default Home


